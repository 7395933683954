<template>
  <main class="bg-white">
    <section class="core">
      <div class="form-box">
        <div class="model-title row-between">
          <div class="column">
            <div class="title lg mb10 normal">大雨结算页</div>
            <div class="sub-title lighter nr mb20">确认订单信息</div>
          </div>
          <div class="flow-box row xs primary">
            <div class="flow-item column-center op">
              <div class="item-num white bold">1</div>
              <div class="item-title">1.我的购物车</div>
            </div>
            <div class="flow-item column-center">
              <div class="item-num white bold">2</div>
              <div class="item-title">2.填写核对订单信息</div>
            </div>
            <div class="flow-item column-center gray">
              <div class="item-num white bold">3</div>
              <div class="item-title">3.成功提交订单</div>
            </div>
          </div>
        </div>
        <div class="consignee mb20">
          <div class="row-between nr mb20">
            <div class="title normal">收货人信息</div>
            <div class="add primary" @click="
              (addressId = null),
              (addressType = 'add'),
              (dialogVisible = true)
              ">
              新增收货地址
            </div>
          </div>
          <div class="address-list">
            <div class="lighter cursor" v-for="(item, index) in addressList" :key="index" @click="addressSelect = item">
              <div class="mb20 row address-item" :class="{ active: addressSelect.id == item.id }"
                v-if="index <= 2 || addressShow">
                <div class="item-name line1 row-center">{{ item.contact }}</div>
                <div class="item-info row-between flex1">
                  <div class="info-text">
                    {{ item.contact }}&nbsp;{{ item.province }}&nbsp;{{
                      item.district
                    }}&nbsp;{{ item.city }}&nbsp;{{ item.address }}&nbsp;{{
  formatPhone(item.telephone)
}}
                  </div>
                  <div class="info-btn primary">
                    <span v-if="!item.is_default" @click="setDefault(item)">设为默认地址</span>
                    <span @click="
                      (addressId = item.id),
                      (addressType = 'edit'),
                      (dialogVisible = true)
                      ">编辑</span>
                    <span @click="delAddressDetail(item.id)">删除</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="retract xs lighter row mb20 cursor" :class="{ active: addressShow }"
            @click="addressShow = !addressShow" v-if="addressList.length && addressList.length > 3">
            {{ addressShow ? "收起地址" : "展开地址" }}
            <img src="@/assets/icon/icon-arrow7.png" alt="" />
          </div>
        </div>
      </div>
      <div class="order">
        <div class="model-title row-between">
          <div class="column">
            <div class="title lg normal mb10">确认订单信息</div>
          </div>
        </div>
        <div class="order-box lighter nr">
          <div class="order-title row">
            <div class="item-title flex3"><span>商品</span></div>
            <div class="item-title flex1">单价</div>
            <div class="item-title flex1">数量</div>
            <div class="item-title flex1">发货时间</div>
            <div class="item-title flex1">期望到货日</div>
            <div class="item-title flex1">小计</div>
          </div>
          <div class="order-list" v-for="(item, index) in cartInfo.shop" :key="index">
            <!-- <div class="bg-white list-title xs">{{ item.shop_name }}</div> -->
            <div class="order-item row normal nr" v-for="(itemTwo, indexTwo) in item.goods" :key="indexTwo">
              <div class="item-pro flex3 row">
                <el-image class="img mr20 ml30" :src="itemTwo.image" fit="cover"></el-image>
                <div class="pro-title line2">
                  {{ itemTwo.name }}
                </div>
              </div>
              <div class="item-price flex1 row-center">
                <make-priceformat :price="itemTwo.price" :subscriptSize="17" :firstSize="17" :secondSize="17"
                  color="#777777"></make-priceformat>
              </div>
              <div class="item-num flex1 row-center black">
                {{ itemTwo.num }}
              </div>
              <div class="item-time flex1 row-center normal">48小时内发货</div>

              <div class="item-title flex1 row-center normal"> {{ itemTwo.desire_date }}</div>
              <div class="item-count flex1 row-center">
                <make-priceformat :price="itemTwo.sum_price" :subscriptSize="13" :firstSize="20" :secondSize="20"
                  color="#C91623"></make-priceformat>
              </div>
            </div>
            <div class="item-count nr nr normal row-end">
              运费：
              <make-priceformat :price="item.shipping_price" :subscriptSize="16" :firstSize="16" :secondSize="16"
                color="#333"></make-priceformat>
              &nbsp; &nbsp; 合计：
              <make-priceformat :price="item.total_amount" :subscriptSize="16" :firstSize="16" :secondSize="16"
                color="#333"></make-priceformat>
            </div>
          </div>
        </div>
        <el-row>
          <el-col :span="20">
            <div class="title lg normal mb10">是否加急</div>
          </el-col>
          <el-col :span="4"><el-switch v-model="urgent" active-text="加急" inactive-text="默认" :inactive-value="0"
              :active-value="1">
            </el-switch></el-col>
        </el-row>
        <br />
        <div class="model-title row-between">
          <div class="column">
            <div class="title lg normal mb10">订单备注</div>
          </div>
        </div>
        <div class="row-between remark">
          <el-input type="textarea" style="width: 500px" resize="none" :autosize="{ minRows: 4, maxRows: 8 }"
            placeholder="请输入备注信息" v-model="remark">
          </el-input>
          <div class="order-info">
            <p class="lighter sm mb10">
              {{ cart_data.length || 0 }}件商品，总商品金额：
              <make-priceformat :price="cartInfo.goods_amount" :subscriptSize="15" :firstSize="15" :secondSize="15"
                color="#666666"></make-priceformat>
            </p>
            <p class="lighter sm mb10">
              运费：
              <make-priceformat :price="totalShipping" :subscriptSize="15" :firstSize="15" :secondSize="15"
                color="#666666"></make-priceformat>
            </p>
            <p class="lighter sm mb10">
              满减金额：
              <make-priceformat :price="cartInfo.reduct_amount" :subscriptSize="15" :firstSize="15" :secondSize="15"
                color="#666666"></make-priceformat>
            </p>
            <div class="info-detail">
              <p class="mb20">
                <span class="black mr10">实付款:</span>
                <make-priceformat :price="cartInfo.order_amount" :subscriptSize="20" :firstSize="34" :secondSize="34"
                  :weight="700" color="#C91623"></make-priceformat>
              </p>
              <div class="lighter mb10 row-end">
                <span class="black mr10">寄送至:</span>
                <p>
                  <span v-if="JSON.stringify(addressSelect) != '{}'">
                    {{ addressSelect.province }}&nbsp;
                    {{ addressSelect.city }}&nbsp;
                    {{ addressSelect.district }}&nbsp;
                    {{ addressSelect.address }}</span><span v-else>请选择收货地址</span>
                </p>
              </div>
              <div class="lighter mb10 row-end">
                <span class="black mr10">收货人:</span>
                <p>
                  <span v-if="JSON.stringify(addressSelect) != '{}'">
                    <span class="mr10"> {{ addressSelect.contact }}收</span><span>{{ formatPhone(addressSelect.telephone)
                    }}</span>
                  </span>
                  <span v-else>请选择收货地址</span>
                </p>
              </div>
            </div>
            <div class="operate row">
              <!-- <router-link to="" @click.native='jslink'>
                <div class="operate-back row lg lighter" >
                  <img src="@/assets/icon/icon-back.png" alt="" class="mr10" />
                  返回购物车
                </div>
              </router-link> -->
              <button type="primary" class="white lg row-center" @click="submitOrder">
                提交订单
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <el-dialog :title="addressType == 'add' ? '创建地址' : '编辑地址'" :visible.sync="dialogVisible" width="893px"
      :show-close="false" custom-class="address-dialog">
      <div class="add-title nr primary row mb20 bg-center">
        {{ addressType == "add" ? "创建" : "编辑" }}收货地址
      </div>
      <add-address labelWidth="250" @successAddress="getAddressList(), (dialogVisible = false)" :type="addressType"
        :id="addressId"></add-address>
    </el-dialog>
  </main>
</template>
<script>
import AddAddress from "../../user/center/components/AddAddress.vue";
export default {
  name: "",
  props: {},
  components: {
    "add-address": AddAddress,
  },
  data() {
    return {
      urgent: false,
      dialogVisible: false,
      addressSelect: {},
      cartInfo: {}, //选择的商品
      addressList: [], //地址列表
      addressShow: false, //地址是否展示
      addressType: "add", //地址添加编辑类型 add 添加 edit 编辑
      addressId: null, //当前编辑的地址ID
      totalShipping: 0, //当前总运费
      cart_id: "", //购物车ID
      cart_data: "", //购物车列表
      remark: "",
      active_type: 0,
    };
  },
  computed: {},
  watch: {},
  methods: {

    //js跳转传参是一样的

    jslink() {
      var id = this.$route.query.id
      console.log(id);
      this.$router.push({ name: 'planCar', query: { id: id } })
    },

    /**
     * @description 获取地址列表
     * @param {Number} addressId 地址ID
     * @return void
     */
    getAddressList(addressId = null) {
      this.$api.getAddressListApi().then((res) => {
        this.addressList = res.data;
        if (addressId) {
          this.addressSelect = res.data.filter((el) => {
            return el.id == addressId;
          })[0];
        } else {
          let obj = res.data.filter((el) => {
            return el.is_default == 1;
          });
          if (obj.length) {
            this.addressSelect = obj[0];
          }
        }
      });
    },

    /**
     * @description 格式化手机
     * @param {String | Number} val
     * @return 138-0000-0000
     */
    formatPhone(val) {
      if (val) {
        let matches = /^(\d{3})(\d{4})(\d{4})$/.exec(val);
        if (matches) {
          val = matches[1] + "-" + matches[2] + "-" + matches[3];
        }
      }
      return val;
    },
    /**
     * @description 设置默认地址
     * @param {Object} item
     * @return void
     */
    setDefault(item) {
      // if (item.is_default) return;
      this.$api
        .setDefaultApi({
          id: item.id,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getAddressList();
        });
    },
    /**
     * @description 删除地址
     * @param {Number | String} id
     * @return void
     */
    delAddressDetail(id) {
      this.$api
        .delAddressDetailApi({
          id,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getAddressList();
        });
    },
    /**
     * @desciption 购物车下单 -- step 1
     * @return void
     */
    async submitOrder() {
      let { addressSelect, cart_id, cart_data: goods } = this;
      let params = {
        address_id: addressSelect.id,
        active_type: this.active_type,
        cart_id,
        goods: JSON.stringify(goods),
        distribution_type: 0,
        remark: this.remark,
        urgent: this.urgent,
        is_xiangya: 1
      };
      let { code, data, msg } = await this.$api.submitOrderApi(params);
      if (code == 1) {
        this.$message({
          message: msg,
          type: "success",
        });
        this.$router.push({
          name: "OrderList",
        });
      }
    },
    /**
     * @description 设置结算单
     * @returns void
     */
    async SetTlement() {

      let { code, data, value1 } = await this.$api.setPlanTlementApi({
        cart_id: this.cart_id,
        goods: JSON.stringify(this.cart_data),
        value1: this.value1,
      });
      if (code == 1) {
        this.cartInfo = data;
        data.shop.forEach((el) => {
          this.totalShipping += el.shipping_price;
        });
      } else {
        this.$router.go(-1);
      }
      // if (code == 301) {
      //   this.$router.go(-1);
      // }
    },
  },
  async created() {
    let { cart_id, data, addressId, active_type } = JSON.parse(
      decodeURIComponent(this.$route.query.data)
    );
    this.cart_id = cart_id;
    this.cart_data = data;
    this.active_type = active_type || 0;
    await this.SetTlement();
    this.getAddressList(addressId);
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang="scss" scoped>
section {
  padding: 40px 0;

  .model-title {
    border-bottom: $-solid-border;
    margin-bottom: 20px;
  }
}

.form-box {
  .flow-box {
    .flow-item {
      width: 160px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 8px;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: $-color-primary;
      }

      &.op {
        opacity: 0.3;
      }

      &.gray {

        .item-num,
        &::before {
          background-color: #cccccc !important;
        }

        .item-title {
          color: #cccccc;
        }
      }

      .item-num {
        position: relative;
        z-index: 2;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $-color-primary;
        line-height: 20px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }

  .consignee {
    border-bottom: $-solid-border;

    .add {
      cursor: pointer;
    }

    .address-list {
      .address-item {
        height: 34px;

        &:hover {
          background: #f7f7f7;

          .item-name {
            border: 2px solid $-color-primary;
            color: $-color-primary;
            background-color: white;
          }

          .info-btn {
            display: block;
          }
        }

        &.active {
          .item-name {
            border: 2px solid $-color-primary;
            color: $-color-primary;
            background-color: white;
          }

          .info-text {
            color: $-color-primary;
          }
        }

        .item-name {
          width: 110px;
          border: 2px solid $-color-border;
          padding: 0 10px;
          flex-shrink: 0;
          height: inherit;
        }

        .item-info {
          height: inherit;
          padding-left: 20px;
          padding-right: 56px;
          letter-spacing: 1px;
        }

        .info-btn {
          display: none;

          span {
            margin-right: 30px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .retract {
      img {
        transform: rotate(180deg);
      }

      &.active {
        img {
          transform: rotate(0);
        }
      }

      img {
        width: 12px;
        margin-left: 10px;
      }
    }
  }
}

.remark {
  align-items: flex-start;
}

.order {
  .order-box {
    background: #f7f7f7;
    margin-bottom: 50px;

    .order-title {
      height: 52px;
      line-height: 52px;
      border-bottom: $-solid-border;

      span {
        padding-left: 186px;
      }

      .item-title {
        flex-shrink: 0;
      }

      .flex1 {
        text-align: center;
      }
    }

    .order-list {
      .list-title {
        padding: 15px 10px 10px;
      }

      .order-item {
        padding: 25px 0;
        border-bottom: $-solid-border;

        .item-pro {
          .img {
            width: 64px;
            height: 64px;
          }

          .pro-title {
            width: 296px;
          }
        }
      }

      .item-count {
        padding: 10px;
      }
    }
  }

  .order-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;

    .info-detail {
      margin-top: 35px;
    }
  }

  .operate {
    .operate-back {
      margin-right: 26px;
      cursor: pointer;

      img {
        width: 22px;
      }
    }

    button {
      cursor: pointer;
      width: 183px;
      height: 51px;
    }
  }
}

/deep/ .address-dialog {
  .el-dialog__header {
    padding: 30px;
  }

  .el-dialog__body {
    padding: 10px 147px 60px 0;

    .add-title {
      width: 587px;
      height: 42px;
      margin-left: 160px;
      padding-left: 15px;
    }
  }
}
</style>
